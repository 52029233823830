import React from 'react'
import {PATH_DASHBOARD } from '../../routes/paths';
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------
const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  user: getIcon('ic_user'),
  group: getIcon('ic_group'),
  trainer: getIcon('ic_trainer'),
  exercises: getIcon('ic_exercises'),
  workout: getIcon('ic_workout'),
  meals: getIcon('ic_meals'),
  programs: getIcon('ic_programs'),
  settings: getIcon('ic_settings')
};

const sidebarConfig = [
  {
    subheader: 'menu',
    items: [
      { title: 'overview', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'Users', path: PATH_DASHBOARD.users.list, icon: ICONS.user },
      { title: 'Clinics', path: PATH_DASHBOARD.clinics.list, icon: ICONS.user },
      { title: 'Appointments', path: PATH_DASHBOARD.appointment.list, icon: ICONS.user },
    ]
  }
];

export { sidebarConfig };
