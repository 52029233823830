import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/authentication/Login';
import Page404 from '../pages/Page404';


// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, user } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }
  // if (user.role === 'admin') {
  //   let x = pathname.split('/');
  //   if (x[1] !== 'admin') {
  //     return <Page404 />;
  //   }
  // }
  // if (user.role === 'trainer') {
  //   let x = pathname.split('/');
  //   if (x[2] === 'works') {
  //     return <Page404 />;
  //   }
  // }
  // if (user.role === 'trainer' || user.role === 'partner') {
  //   let x = pathname.split('/');
  //   if (x[1] === 'admin') {
  //     return <Page404 />;
  //   }
  // }

  return (
    <>
      {children}
    </>
  );
}
