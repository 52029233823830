// ----------------------------------------------------------------------



function path(root, sublink) {
  return `${root}${sublink}`;
}
// const ROOTS_CHANGE_PASS = '/api/auth';
const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_DASHBOARD_ADMIN = '/admin';



export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  // loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  // register: path(ROOTS_AUTH, '/signup'),
  // registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  // resetPassword: path(ROOTS_AUTH, '/reset-password'),
  // verify: path(ROOTS_AUTH, '/verify'),
  // changePassword: path(ROOTS_CHANGE_PASS, '/password/reset/:params')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_ADMIN_DASHBOARD = {
  root: ROOTS_DASHBOARD_ADMIN,
  general: {
    app: path(ROOTS_DASHBOARD_ADMIN, '/app'),
    profile: path(ROOTS_DASHBOARD_ADMIN, '/profile')
  },
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app')
  },
  // ROOT MENU
  users: {
    root: path(ROOTS_DASHBOARD, '/users'),
    list: path(ROOTS_DASHBOARD, '/users/list'),
  },
  clinics: {
    root: path(ROOTS_DASHBOARD, '/clinics'),
    list: path(ROOTS_DASHBOARD, '/clinics/list'),
  },
  appointment: {
    root: path(ROOTS_DASHBOARD, '/appointment'),
    list: path(ROOTS_DASHBOARD, '/appointment/list'),
  },
};

export const PATH_DOCS = '';
