import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import LoadingScreen from '../components/LoadingScreen';
const Loadable = (Component) => (props) => {
  // const { pathname } = useLocation();
  // const isDashboard = pathname?.includes('/dashboard');
  const isDashboard = true;

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { path: '/', element: <Navigate to="/auth/login" replace /> },
      ]
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
      ]
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/dashboard', element: <Navigate to="app" replace /> },
        { path: 'app', element: <GeneralApp /> },
        { path: 'users/list', element: <UsersList /> },
        { path: 'clinics/list', element: <ClinicsList /> },
        { path: 'appointment/list', element: <AppointmentList /> },
      ]
    },
    { path: '/404', element: <General404 /> },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const UsersList = Loadable(lazy(() => import('../pages/users/UsersList')));
const ClinicsList = Loadable(lazy(() => import('../pages/clinics/ClinicsList')));
const AppointmentList = Loadable(lazy(() => import('../pages/appointment/AppointmentList')));
const General404 = Loadable(lazy(() => import('../pages/Page404')));


