import Router from './routes';
import ThemeConfig from './theme';
import useAuth from './hooks/useAuth';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';

export default function App() {
	const { isInitialized } = useAuth();
	return (
		<ThemeConfig>
			<ThemePrimaryColor>
				<ThemeLocalization>
					<RtlLayout>
						<NotistackProvider>
							<ScrollToTop />
							{isInitialized ? <Router /> : <LoadingScreen />}
						</NotistackProvider>
					</RtlLayout>
				</ThemeLocalization>
			</ThemePrimaryColor>
		</ThemeConfig>
	);
}
