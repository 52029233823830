import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg width="100%" height="100%"  viewBox="0 0 211 205" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="108.254" cy="16.7265" r="14.3848" stroke="#8C2DD4" stroke-width="4.68342"/>
        <circle cx="108.254" cy="188.273" r="14.3848" stroke="#FF82CD" stroke-width="4.68342"/>
        <circle cx="16.7265" cy="102.366" r="14.3848" stroke="#2114D1" stroke-width="4.68342"/>
        <circle cx="194.161" cy="102.366" r="14.3848" stroke="#32CD32" stroke-width="4.68342"/>
        <path d="M57.4796 51.5919C68.8067 40.2648 87.1715 40.2648 98.4986 51.5919L158.871 111.964C170.198 123.291 170.198 141.656 158.871 152.983C147.544 164.31 129.179 164.31 117.852 152.983L57.4796 92.6108C46.1526 81.2838 46.1526 62.919 57.4796 51.5919Z" stroke="#5EAAD9" stroke-width="5.35248"/>
        <path d="M57.5901 152.983C46.2631 141.656 46.2631 123.291 57.5901 111.964L117.962 51.5922C129.289 40.2652 147.654 40.2652 158.981 51.5923C170.308 62.9193 170.308 81.2841 158.981 92.6112L98.6091 152.983C87.282 164.31 68.9172 164.31 57.5901 152.983Z" stroke="#5EAAD9" stroke-width="5.35248"/>
      </svg>

    </Box>
  );
}
